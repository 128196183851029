import { useCallback, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { Cookies, withCookies } from "react-cookie";
import { Button } from "react-bootstrap";

const { InputPasswordGroup } = require("@elementor/eds-components");

function safeRedirect(target: string) {
  const targetUrl = new URL(target);
  const redirectUrl = new URL(
    targetUrl.pathname + targetUrl.search + targetUrl.hash,
    window.location.origin
  );
  window.location.href = redirectUrl.toString();
}

const LockForm = ({ cookies }: { cookies: Cookies }) => {
  const [password, setPassword] = useState("");
  const [busy, setBusy] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [failed, setFailed] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [touched, setTouched] = useState(false);

  const [redirect] = useQueryParam("redirect", StringParam);

  const handlePasswordChange = useCallback((event) => {
    setTouched(true);
    setPassword(event.target.value);
  }, []);

  const handleSubmitForm = useCallback(
    async (event) => {
      event.preventDefault();
      setRejected(false);
      setFailed(false);
      setBusy(true);

      try {
        const payload = { password, redirect };
        const response = await fetch("./api/login", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          const { success, cookie, redirect, cookiePath } =
            await response.json();

          if (success) {
            setRedirecting(true);

            const [[name, value]] = Object.entries(cookie);

            // Set cookie
            cookies.set(name, value, { path: cookiePath ?? "/" });

            safeRedirect(redirect);
          } else {
            setRejected(true);
          }
        } else {
          setFailed(true);
        }
      } catch (error) {
        setFailed(true);
      } finally {
        setTouched(false);
        setBusy(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [password, redirect]
  );

  return (
    <>
      <form onSubmit={handleSubmitForm}>
        <div className="form-group text-left">
          <InputPasswordGroup
            readOnly={false}
            value={password}
            onChange={handlePasswordChange}
            placeholder={"Enter the pin code to view it"}
            errors={"The code you've entered is incorrect!"}
            isInvalid={rejected}
          />
        </div>
        {failed && (
          <div className="alert alert-warning" role="alert">
            Oops! We have encountered an error while validating your code!
            Please try again later.
          </div>
        )}

        <div className="form-group">
          <Button
            type="submit"
            className={`btn btn-lg btn-block ${
              !touched || redirecting ? "" : "btn-accent"
            } ${busy ? "btn-is-loading" : ""}`}
            disabled={!touched || password.length === 0 || busy || redirecting}
          >
            {busy ? (
              <span>
                <span className="spinner-gradient spinner-sm spinner-gradient-light" />
                <span className="ml-3 text-gray-500 sr-only">Loading...</span>
              </span>
            ) : (
              "Continue"
            )}
          </Button>
        </div>
      </form>
    </>
  );
};

export const LockFormWithCookies = withCookies(LockForm);
